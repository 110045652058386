import { replacePharma } from '../api/pharmacy.js';
import { drxenroll } from '../api/igse-session.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enroDRX = async (userData: any) => {
  try {
    await replacePharma({ session: userData.session.session, pharmacyList: userData.pharmacyList });
    // Regex match to ignore case
    const customTrackKey = Object.keys(userData.session.sanitizedParams).find((key) => key.match(/CustomTrack1/i));
    const redirect = await drxenroll({
      Custom4: `${customTrackKey ? userData.session.sanitizedParams[customTrackKey] : ''}`,
      zipCode: `${userData.session.location?.zip}`,
      fipCode: `${userData.session.location?.fip}`,
      sessionID: `${userData.session.session}`,
      selectedPlan: `${userData.selectedPlan}`,
      query: `${userData.session.query}`,
      uricampaign: `${userData.session.campaign}`,
      state: `${userData.session.location.state}`,
      year: `${userData.session.year.toString()}`,
    });
    window.location.href = `${redirect}`;
  } catch (error) {
    throw 'drxenroll!200';
  }
};
