/* eslint-disable @typescript-eslint/no-explicit-any */
import { sanitize } from '../../utils/sanitize.js';
import { getParams } from '../../url.js';
import { environment } from '../../app.js';
const DRXendpoint = environment.endpoints.drxapi;

export const initsession = async () => {
  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);
  const sanitizedParams = JSON.parse(sanitize(JSON.stringify(getParams(location.search))));
  const adobeCookie = document.cookie
    ?.split(';')
    ?.filter((c) => c.trim().startsWith('AMCV_'))[0]
    ?.split('MCMID%7C')[1]
    ?.split('%7C')[0];
  const customFields = {
    CustomField1: sanitizedParams[Object.keys(sanitizedParams).find((key) => key.match(/GCLID/i)) || ''],
    CustomField2: adobeCookie,
    CustomField3: sanitizedParams[Object.keys(sanitizedParams).find((key) => key.match(/(EID)|(PID)/i)) || ''],
  };
  const host = DRXendpoint;
  const uri = '?action=session-create';

  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    body: JSON.stringify(customFields),
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'DRXinitsession!200';
  }
  return response.json();
};

export const editSession = async (data: any) => {
  const host = DRXendpoint;
  const uri = `?action=session-update&subsidyLevel=${Number(data.sub.help_option)}&subsidyPercent=${Number(
    data.sub.partialhelp_option ? data.sub.partialhelp_option : 0,
  )}&sessionID=${data.session}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drx edit session call response not 200';
  }
  // eslint-disable-next-line sonarjs/no-redundant-jump
  return;
};

export const drxenroll = async (data: any) => {
  const host = DRXendpoint;
  let uri = `?action=plan-compare-enroll&sessionID=${data.sessionID}&drxplanID=${data.selectedPlan}`;

  if (data.uricampaign) {
    uri += `&campaign_ID=${data.uricampaign}`;
  }
  if (data.query) {
    uri += `&${data.query}`;
  }

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const enrollpayload: Record<string, unknown> = {
    Enroller: {
      PartnerID: '5',
      UserType: 'CONSUMER',
    },
    Enrollee: {
      Zip: `${data.zipCode}`,
      CountyFIPS: `${data.fipCode}`,
      ...(data.year !== '2024' && { State: `${data.state}` }),
    },
    Language: 'en-US',
  };
  ['Custom4'].forEach((key: any) => {
    if (data[key].length > 0) {
      enrollpayload[key] = data[key];
    }
  });
  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    body: JSON.stringify(enrollpayload),
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drxenroll!200';
  }
  return await response.text();
};
