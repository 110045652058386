import React, { DOMAttributes } from 'react';
import { environment } from './environments/environment.js';

// Data
import { DrugSelectorProvider } from './hooks/use-drug-selector.js';
import { PharmacyProvider } from './context/pharmacy-context.js';
import { UserProvider } from './context/user-context.js';
import { AnalyticsProvider } from './context/analytics-context.js';
import { UserPlanSelectorProvider } from './hooks/use-user-plan-selector.js';
import { ErrorStatesProvider } from './hooks/use-error-states.js';
import './app.scss';

// Class
import { providersArray } from './class/providers.js';
import { hasStateChanged } from './class/hasStateChanged.js';
import Root from './root.js';

export const currentState = new hasStateChanged();
export const myProviders = new providersArray();
export { environment };

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'chc-heading-list': CustomElement<any>;
      'chc-heading-list-item': CustomElement<any>;
      'chc-igse-product-card': CustomElement<any>;
      'chc-phone-number': CustomElement<any>;
      'chc-promo': CustomElement<any>;
      'chc-table': CustomElement<any>;
      'leaf-accordion': CustomElement<any>;
      'leaf-accordion-panel': CustomElement<any>;
      'leaf-accordion-panel-inline': CustomElement<any>;
      'leaf-alert': CustomElement<any>;
      'leaf-button': CustomElement<any>;
      'leaf-button-link': CustomElement<any>;
      'leaf-card': CustomElement<any>;
      'leaf-checkgroup': CustomElement<any>;
      'leaf-checkbox': CustomElement<any>;
      'leaf-date-picker': CustomElement<any>;
      'leaf-divider': CustomElement<any>;
      'leaf-dropdown': CustomElement<any>;
      'leaf-dropdown-item': CustomElement<any>;
      'leaf-flex-container': CustomElement<any>;
      'leaf-icon': CustomElement<any>;
      'leaf-impression-wrapper': CustomElement<any>;
      'leaf-inputgroup': CustomElement<any>;
      'leaf-input': CustomElement<any>;
      'leaf-label': CustomElement<any>;
      'leaf-list': CustomElement<any>;
      'leaf-modal': CustomElement<any>;
      'leaf-popover': CustomElement<any>;
      'leaf-progress-bar': CustomElement<any>;
      'leaf-radio': CustomElement<any>;
      'leaf-radiogroup': CustomElement<any>;
      'leaf-rating': CustomElement<any>;
      'leaf-select': CustomElement<any>;
      'leaf-select-option': CustomElement<any>;
      'leaf-spinner': CustomElement<any>;
      'leaf-tab': CustomElement<any>;
      'leaf-tab-item': CustomElement<any>;
      'leaf-tab-section': CustomElement<any>;
      'leaf-validation-message': CustomElement<any>;
      'chc-row': CustomElement<any>;
      'chc-search': CustomElement<any>;
    }
  }
}

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <UserProvider>
      <UserPlanSelectorProvider>
        <DrugSelectorProvider>
          <PharmacyProvider>
            <ErrorStatesProvider>
              <AnalyticsProvider>
                <Root />
              </AnalyticsProvider>
            </ErrorStatesProvider>
          </PharmacyProvider>
        </DrugSelectorProvider>
      </UserPlanSelectorProvider>
    </UserProvider>
  );
};

export default App;
