import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import './shopping-wizard.scss';
import ResultsSection from './results-section.js';
// import useUserData from '../../hooks/use-user-data.js';
import type { Step } from './interfaces.js';

interface ShoppingWizardProps {}

interface IWizardState {
  heading: string | React.JSX.Element;
  currentStep: number;
  score: { [key: string]: number };
  nextOrFinish: string;
  possibleValues: { [key: string]: number };
}

const ShoppingWizard: FC<ShoppingWizardProps> = () => {
  // const userData = useUserData();
  const shoppingWizardData = useTranslation('shopping-wizard');

  let rootRef = useRef<HTMLDivElement>(null);
  let _backBtn = useRef<HTMLDivElement>(null);

  const [shoppingWizardState, setShoppingWizardState] = useState<IWizardState>({
    heading: '',
    currentStep: 0,
    score: { B: 0, A: 0 },
    nextOrFinish: '',
    possibleValues: { B: 0, A: 0 },
  });

  const startOver = () => {
    showResults();
    shoppingWizardState.currentStep = 0;
    setShoppingWizardState((prevState) => ({
      ...prevState,
      currentStep: 0,
      score: { B: 0, A: 0 },
      nextOrFinish: shoppingWizardData.t('misc.nextBtn'),
      heading: renderHeading(),
    }));
    const groups = rootRef.current?.querySelectorAll('chc-heading-list-item');
    if (groups) {
      [groups[groups.length - 1], groups[0], _backBtn.current]?.forEach((el) => {
        el?.toggleAttribute('hidden');
      });
    }
    getPossibleValues();
  };

  const renderStepContent = (step: { content: string }) => {
    return step.content && <div className="step-content">{step.content}</div>;
  };

  const renderWizardOptions = (step: Step, index = 0, value: string) => {
    return (
      step.options.length > 0 && (
        <form>
          <leaf-radiogroup
            required
            key={`step-${index + 1}`}
            name={`step-${index + 1}`}
            id={`step-id-${index + 1}`}
            value={value}
          >
            {step.options?.map((option, ind) => (
              <leaf-radio key={`step-${index + 1}-option-${ind + 1}`} value={option.value}>
                {HTMLReactParser(option.label)}
              </leaf-radio>
            ))}
          </leaf-radiogroup>
        </form>
      )
    );
  };

  const renderFootNote = (step: Step) => {
    if (step.footNote) {
      return <div className="foot-note">{step.footNote as any}</div>;
    } else {
      return;
    }
  };

  const renderSteps = () => {
    const thisSteps: Array<Step> = shoppingWizardData?.t('steps', { returnObjects: true });
    return (
      <chc-heading-list color="green">
        {thisSteps?.map((step, index) => {
          const props = {
            slot: 'item',
            ...(thisSteps?.indexOf(step) !== 0 && { hidden: true }),
          };
          return (
            <chc-heading-list-item {...props} key={'steps' + index}>
              {renderStepContent(step)} {renderWizardOptions(step, index, step.options[0].value)} {renderFootNote(step)}
            </chc-heading-list-item>
          );
        })}
      </chc-heading-list>
    );
  };

  const updateScore = () => {
    rootRef.current?.querySelectorAll('leaf-radiogroup').forEach((item) => {
      const option = item.getAttribute('value');
      if (option) {
        shoppingWizardState.possibleValues[`${option}`] += 1;
      }
    });
    shoppingWizardState.score = shoppingWizardState.possibleValues;
  };

  const getPossibleValues = () => {
    const values: { [x: string]: number } = {};
    (shoppingWizardData?.t('steps', { returnObjects: true }) as Array<Step>)?.forEach((step) => {
      step.options.forEach((option: { value: string }) => {
        if (!(option.value in values)) {
          values[option.value] = 0;
        }
      });
    });
    shoppingWizardState.possibleValues = values;
  };

  const renderHeading = (result: string = '') => {
    const thisSteps: Array<Step> = shoppingWizardData?.t('steps', { returnObjects: true });
    return result.length > 0 ? (
      result
    ) : (
      <>
        {thisSteps[shoppingWizardState.currentStep].header} ({shoppingWizardState.currentStep + 1} of{' '}
        {thisSteps?.length})
      </>
    );
  };

  const showResults = () => {
    const score = shoppingWizardState.score;
    const getWinner = Object.keys(score).reduce((a, b) => (score[a] > score[b] ? a : b));
    const getWinnerObj = (
      shoppingWizardData?.t('results', { returnObjects: true }) as Array<{ result: string; heading: string }>
    ).filter((obj) => obj.result === getWinner);
    [
      'chc-heading-list',
      '#wizard-next',
      '#wizard-back',
      '#wizard-start-over',
      'chcapp-medicare-plan-finder',
      'leaf-progress-bar',
    ]?.forEach((el) => rootRef.current?.querySelector(el)?.toggleAttribute('hidden'));
    rootRef.current
      ?.querySelectorAll(`div[data-result="${getWinner}"]`)
      .forEach((el: { toggleAttribute: (arg0: string) => any }) => el?.toggleAttribute('hidden'));
    if (getWinnerObj && getWinnerObj[0].heading) {
      shoppingWizardState.heading = renderHeading(getWinnerObj[0].heading);
    }
  };

  const toggleStepView = (direction = 'next') => {
    const groups = rootRef.current?.querySelectorAll('chc-heading-list-item') || [];
    const currentGroup = [...groups].filter((group) => !group.hasAttribute('hidden'));
    const nextIndex =
      direction === 'next' ? [...groups].indexOf(currentGroup[0]) + 1 : [...groups].indexOf(currentGroup[0]) - 1;
    const isComplete = nextIndex + 1 >= groups.length;

    shoppingWizardState.nextOrFinish = isComplete
      ? shoppingWizardData?.t('misc.finish')
      : shoppingWizardData?.t('misc.nextBtn');
    if (nextIndex === groups.length) {
      updateScore();
      showResults();
      shoppingWizardState.currentStep += 1;
    } else {
      currentGroup[0].toggleAttribute('hidden');
      groups[nextIndex].toggleAttribute('hidden');
      shoppingWizardState.currentStep = nextIndex;
      if (nextIndex === 0) {
        _backBtn.current?.setAttribute('hidden', '');
      }
      if (direction === 'next') _backBtn.current?.removeAttribute('hidden');
      if (nextIndex === 0) _backBtn.current?.setAttribute('hidden', '');
      shoppingWizardState.heading = renderHeading();
    }
    setShoppingWizardState((prevState) => ({
      ...prevState,
      currentStep: shoppingWizardState.currentStep,
    }));
  };

  const next = () => {
    toggleStepView();
  };

  const back = () => {
    toggleStepView('back');
  };

  useEffect(() => {
    setShoppingWizardState((prevState) => ({
      ...prevState,
      nextOrFinish: shoppingWizardData.t('misc.nextBtn'),
      heading: renderHeading(),
    }));
    getPossibleValues();
    const thisSteps: Array<Step> = shoppingWizardData?.t('steps', { returnObjects: true });
    setTimeout(() => {
      thisSteps?.map((_step, index) => {
        rootRef.current?.querySelector(`#step-id-${index + 1} > leaf-radio:nth-child(1)`)?.setAttribute('checked', '');
      });
    }, 1000);
  }, []);

  return (
    <>
      <div className="chcapp-shopping-wizard" ref={rootRef}>
        <leaf-modal id="shopping-wizard-modal" size="md">
          <h2 slot="heading" className="leaf-u-typography-title-large">
            {shoppingWizardState.heading}
          </h2>
          <leaf-progress-bar
            variant="plain"
            value={shoppingWizardState.currentStep + 1}
            max={(shoppingWizardData?.t('steps', { returnObjects: true }) as Array<Step>)?.length}
            showFullBar={true}
            precision="1"
          ></leaf-progress-bar>
          <div>
            {renderSteps()}
            <ResultsSection />
          </div>
          <leaf-button
            variant="secondary"
            id="wizard-start-over"
            class="chcapp-shopping-wizard--start-over-button"
            hidden
            onClick={startOver}
          >
            {shoppingWizardData?.t('misc.startOver')}
          </leaf-button>
          <leaf-flex-container slot="cta">
            <leaf-button variant="primary" id="wizard-next" onClick={next}>
              {shoppingWizardState.nextOrFinish}
            </leaf-button>
            <leaf-button ref={_backBtn} variant="secondary" id="wizard-back" hidden onClick={back}>
              {shoppingWizardData?.t('misc.backBtn')}
            </leaf-button>
          </leaf-flex-container>
        </leaf-modal>
      </div>
    </>
  );
};

export default ShoppingWizard;
