import { useEffect } from 'react';
import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation, RelativeRoutingType } from 'react-router-dom';
import { getParams } from '../url.js';

interface URLWithState {
  url: string;
  options?: {
    replace?: boolean;
    state?: any;
    relative?: RelativeRoutingType;
  };
}

interface RouterHistoryContextValue {
  hasHistory: boolean;
  stack: Set<URLWithState>;
  reRouteWithLanguage(lng: string): void;
}

export const RouterHistoryContext = React.createContext<RouterHistoryContextValue>({
  hasHistory: false,
  stack: new Set(),
  reRouteWithLanguage: () => {},
});

export interface LocationHistoryContextProps {
  children: React.ReactNode;
}

export const useRouterHistory = () => {
  const { hasHistory, stack, reRouteWithLanguage } = React.useContext(RouterHistoryContext);

  return { hasHistory, stack, reRouteWithLanguage };
};

export const HistoryProvider = (props: LocationHistoryContextProps) => {
  const [locationHistory, setLocationHistory] = useState<Set<URLWithState>>(new Set());
  const navigate = useNavigate();
  const routeLocation = useLocation();

  const reRouteWithLanguage = (language: string): void => {
    const updatedHistory = new Set<URLWithState>();
    const stack = locationHistory;
    window.digitalData.language = language === 'es-us' ? 'Spanish' : 'English';
    document.documentElement.lang = language.replace('-us', '');

    stack.forEach(({ url, options }) => {
      try {
        const urlObj = new URL(`${window.location.origin}${url}`);
        const currentParams: any = getParams(window.location.search);
        urlObj.searchParams.set('lng', language);
        urlObj.searchParams.set('PlanYear', currentParams?.PlanYear || '2025');
        const path = urlObj.href.replace(urlObj.origin, '');
        updatedHistory.add({ url: path, options: routeLocation });
        navigate(path, options);
      } catch (error) {
        console.error(`Invalid URL: ${url}`);
      }
    });

    setLocationHistory(updatedHistory);
  };

  useEffect(() => {
    let path = window.location.href.replace(window.location.origin, '');
    // setLocationHistory((prev) => new Set([...prev, path]));
    setLocationHistory((prev) => {
      const updatedSet = new Set(prev);
      updatedSet.add({ url: path, options: routeLocation });
      return updatedSet;
    });
  }, [routeLocation.pathname, routeLocation]);

  const context = useMemo(() => {
    return {
      /* if the user has visited more than one page */
      hasHistory: locationHistory.size > 1,
      stack: locationHistory,
      reRouteWithLanguage,
    };
  }, [locationHistory]);

  return <RouterHistoryContext.Provider value={context}>{props.children}</RouterHistoryContext.Provider>;
};

export default HistoryProvider;
