import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './compare-box.scss';

import useUserPlanSelector from '../../hooks/use-user-plan-selector.js';
import useUserData from '../../hooks/use-user-data.js';
import { IMedicarePlan } from '../../utils/api/interfaces.js';
import { useNavigate } from 'react-router';
import enOnlyContent from '../../../public/locales/en-only-content.json';
import { isMobile } from '@cigna/dcws_leaf-web-components/utilities/is-mobile.js';

interface CompareBoxProps {}

interface MyState {
  selectedPlans: Array<IMedicarePlan>;
  selectedPlanIds: string[];
}

const CompareBox: FC<CompareBoxProps> = () => {
  const compareBoxContent = useTranslation('compare-box');
  const mySelectedPlans = useUserPlanSelector();
  const userData = useUserData();
  const navigate = useNavigate();
  const [state, setState] = useState<MyState>({
    selectedPlans: [],
    selectedPlanIds: [],
  });

  const handleResize = () => {
    const comparePlanSection = document?.querySelector('#compare-box');
    const resultsSection = document?.querySelector('.results');
    if (isMobile('sm')) {
      document?.querySelectorAll('.selected-plans').forEach((ele, index) => {
        ele.removeAttribute('scroll-target');
        index === 0 && ele.setAttribute('scroll-target', '');
      });
      (comparePlanSection as HTMLElement).style.left = '0px';
      (comparePlanSection as HTMLElement).style.width = '100%';
    } else {
      const left = resultsSection?.getBoundingClientRect().left;
      const width = resultsSection?.getBoundingClientRect().width;
      (comparePlanSection as HTMLElement).style.left = `${left}px`;
      (comparePlanSection as HTMLElement).style.width = `${width}px`;
    }
  };

  const renderSelectedPlan = (plan?: IMedicarePlan, planIndex?: number) => {
    const returnHTML = [];
    const planId = plan?.ID;
    if (plan !== undefined) {
      returnHTML.push(
        <div key={planIndex} className="selected-plans selected" scroll-target={planIndex === 1 ? 'true' : undefined}>
          <h6 className="leaf-u-typography-label-large" id={planId} aria-label={plan.PlanName}>
            {plan.PlanName}
          </h6>
          <leaf-button-link
            class="remove-plan"
            classes="leaf-u-link--no-underline"
            data-planid={planId}
            onClick={removeSelectedPlan}
          >
            <leaf-icon set="system" name="close" label={compareBoxContent.t('misc.removeText')}></leaf-icon>
          </leaf-button-link>
        </div>,
      );
    } else {
      returnHTML.push(
        <div key={planIndex} className="selected-plans">
          <span className="plan-placeholder">{compareBoxContent.t('misc.addPlanText')}</span>
        </div>,
      );
    }
    return returnHTML;
  };

  const renderAllSelectedPlans = () => {
    if (state.selectedPlans.length > 0) {
      const returnHTML = [];
      for (let i = 1; i <= 3; i++) {
        if (state.selectedPlans[i - 1] !== undefined) {
          returnHTML.push(renderSelectedPlan(state.selectedPlans[i - 1], i));
        } else {
          returnHTML.push(renderSelectedPlan(undefined, i));
        }
      }
      return returnHTML;
    }
    return '';
  };

  const updateSelectedPlans = async (planIndex?: number) => {
    if (state.selectedPlanIds.length === 0) {
      document.querySelector('#compare-box')?.classList.remove('show');
    } else {
      if (isMobile()) {
        isPlanVisible(planIndex);
      }
    }
    toggleCompareBtn();
  };

  const isPlanVisible = (planIndex?: number) => {
    if (planIndex !== undefined) {
      const selectedPlan = document?.querySelectorAll('.selected-plans')[planIndex] as HTMLElement;
      const comparePlanContainer = document?.querySelector('.compare-plan-container')! as HTMLElement;
      if (selectedPlan) {
        if (
          !(
            selectedPlan.offsetLeft >= comparePlanContainer.scrollLeft &&
            selectedPlan.offsetLeft + selectedPlan.clientWidth <
              comparePlanContainer.clientWidth + comparePlanContainer.scrollLeft + comparePlanContainer.offsetLeft
          )
        ) {
          if (selectedPlan.offsetLeft < comparePlanContainer.scrollLeft) {
            document?.querySelector('.slide-left')?.dispatchEvent(new Event('click'));
          } else if (selectedPlan.offsetLeft > comparePlanContainer.scrollLeft + comparePlanContainer.clientWidth) {
            document?.querySelector('.slide-right')?.dispatchEvent(new Event('click'));
          }
        }
      }
    }
  };

  const toggleCompareSection = (plan: IMedicarePlan, loadAgain = false) => {
    const card: HTMLElement | null = document
      .querySelector(`leaf-checkbox[data-planid="${plan.ID}"]`)
      ?.closest('chc-igse-product-card') as HTMLElement;
    //TODO: Compare View Analytics
    // const impressionCard = card.shadowRoot?.querySelector('cipublic-product-card');
    // const impression = card?.getAttribute('data-product-custom')?.length ? card?.getAttribute('data-product-custom') : card?.getAttribute('data-impression');
    const compareCheckWrapper = card?.querySelector('leaf-checkbox') as any;
    if (compareCheckWrapper && compareCheckWrapper !== null) {
      const cardPlanId = compareCheckWrapper?.dataset.planid!;
      if (loadAgain) {
        compareCheckWrapper.setAttribute('checked', '');
        card.setAttribute('selected', '');
      }
      if (compareCheckWrapper.hasAttribute('checked')) {
        if (state.selectedPlanIds.indexOf(cardPlanId) === -1 && cardPlanId === plan.ID) {
          state.selectedPlans.push(plan);
          state.selectedPlanIds.push(cardPlanId);
          //TODO: Compare View Analytics
          // comparePlanView.dataImpressionArr.push(impression);
          updateSelectedPlans(state.selectedPlans.length - 1);
        }
        document.querySelector('#compare-box')?.classList.add('show');
      } else {
        compareCheckWrapper.disabled = false;
        const planIdIndex = state.selectedPlanIds.indexOf(cardPlanId);
        if (planIdIndex > -1) {
          state.selectedPlans.splice(planIdIndex, 1);
          state.selectedPlanIds.splice(planIdIndex, 1);
          //TODO: Compare View Analytics
          // comparePlanView.dataImpressionArr.splice(planIdIndex, 1);
          updateSelectedPlans(planIdIndex);
        }
      }
    }
    handleResize();
    setState((prevState) => ({ ...prevState }));
    mySelectedPlans.setCompare(state.selectedPlans);
    userData.setCompareToggle(null);
  };

  const deselectCard = (planId?: string) => {
    const activeTab = document.querySelector('#plans-view')?.querySelector('leaf-tab-section[active]');
    const planCards = activeTab?.querySelectorAll('chc-igse-product-card');
    planCards?.forEach((card) => {
      const compareCheckbox = card?.querySelector('leaf-checkbox') as any;
      if (
        (compareCheckbox !== null && compareCheckbox.hasAttribute('checked') && !planId) ||
        (compareCheckbox !== null && compareCheckbox?.dataset.planid === planId)
      ) {
        compareCheckbox.removeAttribute('checked');
        card.removeAttribute('selected');
      }
    });
  };

  const removeSelectedPlan = (event: Event) => {
    const planId = (event.currentTarget as HTMLElement).dataset.planid;
    const planIdIndex = state.selectedPlanIds.indexOf(planId || '');
    if (planIdIndex > -1) {
      state.selectedPlans.splice(planIdIndex, 1);
      state.selectedPlanIds.splice(planIdIndex, 1);
      const selectedPlanEle = document?.querySelectorAll('.selected-plans')[planIdIndex];
      if (selectedPlanEle?.hasAttribute('scroll-target') && planIdIndex > 0) {
        let selectedPlan;
        if (document?.querySelectorAll('.selected-plans')[planIdIndex - 1]) {
          selectedPlan = document?.querySelectorAll('.selected-plans')[planIdIndex - 1];
        } else if (document?.querySelectorAll('.selected-plans')[planIdIndex + 1]) {
          selectedPlan = document?.querySelectorAll('.selected-plans')[planIdIndex + 1];
        }
        selectedPlan?.setAttribute('scroll-target', '');
      }
    }
    updateSelectedPlans(planIdIndex);
    deselectCard(planId);
    updateCompareCheckbox();
    mySelectedPlans.setCompare(state.selectedPlans);
  };

  const toggleCompareBtn = () => {
    const compareBtns = document?.querySelectorAll('.compare-plan-btn');
    compareBtns?.forEach((compareBtnEl) => {
      if (state.selectedPlanIds.length > 1) {
        compareBtnEl?.removeAttribute('disabled');
      } else {
        compareBtnEl?.setAttribute('disabled', 'disabled');
      }
    });
  };

  const updateCompareCheckbox = () => {
    const activeTab = document.querySelector('#plans-view')?.querySelector('leaf-tab-section[active]')!;
    const planCards = activeTab?.querySelectorAll('chc-igse-product-card');
    const compareDisabled = state.selectedPlanIds.length === 3;
    planCards?.forEach((card) => {
      const compareCheckWrapper = card?.querySelector('leaf-checkbox') as any;
      if (compareCheckWrapper !== null) {
        compareCheckWrapper.removeAttribute('disabled');
        if (!compareCheckWrapper.hasAttribute('checked') && state.selectedPlanIds.length === 3) {
          compareCheckWrapper.setAttribute('disabled', `${compareDisabled}`);
        }
      }
    });
  };

  const clearSelectedPlans = () => {
    // adding empty array will not work
    state.selectedPlanIds = [];
    state.selectedPlans = [];
    setState((prevState) => ({ ...prevState, selectedPlans: [], selectedPlanIds: [] }));
    mySelectedPlans.setCompare([]);
    updateSelectedPlans();
    updateCompareCheckbox();
    deselectCard();
    document.querySelector('#compare-box')?.classList.remove('show');
  };

  const showComparePlanSection = (event: Event) => {
    if (!(event?.target as HTMLButtonElement)?.disabled) {
      mySelectedPlans.setCompare(state.selectedPlans);

      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/compare-plans';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path);
    }
  };

  //TODO: Remove
  useEffect(() => {
    handleResize();
    window.onresize = () => handleResize();
  }, []);

  useEffect(() => {
    if (userData.compareToggle) toggleCompareSection(userData.compareToggle, false);
  }, [userData.compareToggle, mySelectedPlans.compareSelected]);

  useEffect(() => {
    if (!mySelectedPlans.compareSelected) clearSelectedPlans();
  }, [mySelectedPlans.compareSelected]);

  useEffect(() => {
    // Only way I can find to ensure the product cards have loaded in the plans view
    const untilCardsLoaded = () => {
      if (document.querySelectorAll('chc-igse-product-card').length) {
        if (mySelectedPlans.compareSelected?.length && state.selectedPlanIds?.length === 0) {
          mySelectedPlans.compareSelected.forEach((plan: IMedicarePlan) => toggleCompareSection(plan, true));
        }
        updateCompareCheckbox();
      } else
        setTimeout(() => {
          untilCardsLoaded();
        }, 200);
    };
    untilCardsLoaded();
  }, [userData.compareToggle]);

  return (
    <div id="compare-box">
      <div className="compare-plan-section">
        <div className="compare-header">
          <h2 className="leaf-u-typography-title-default">{compareBoxContent.t('misc.compareBoxHeader')}</h2>
          <leaf-button
            variant="primary"
            class="compare-plan-btn"
            onClick={showComparePlanSection}
            data-en-content={enOnlyContent.plansView.comparePlans}
          >
            {compareBoxContent.t('misc.compareBtn')}
          </leaf-button>
          <leaf-button-link classes="leaf-u-link--no-underline" onClick={clearSelectedPlans} class="cancel-btn">
            {compareBoxContent.t('misc.cancelLinkText')}
          </leaf-button-link>
        </div>
        <div className="compare-plan-wrapper">
          <div className="compare-plan-container">
            <div className="compare-plan-grid">{renderAllSelectedPlans()}</div>
            <div className="mobile-btn-wrapper">
              <leaf-button
                variant="primary"
                class="compare-plan-btn"
                onClick={showComparePlanSection}
                data-en-content={enOnlyContent.plansView.comparePlans}
              >
                {compareBoxContent.t('misc.compareBtn')}
              </leaf-button>
              <leaf-button-link classes="leaf-u-link--no-underline" onClick={clearSelectedPlans} class="cancel-btn">
                {compareBoxContent.t('misc.cancelLinkText')}
              </leaf-button-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareBox;
