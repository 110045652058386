import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './ChangeLocation.scss';

import { regexToString, sanitize } from '../../utils/sanitize.js';
import { memZipLookUp } from '../../utils/memoize/memZipCountySearch.js';
import { IZipLookUpResponse } from '../../utils/api/zip-county-search.js';
import { IAnalytics } from '../../context/analytics-context.js';

// Hooks
import useUserData from '../../hooks/use-user-data.js';
import { IUserSession } from '../../context/user-context.js';
import HTMLReactParser from 'html-react-parser';
import { ISearchParams, getParams } from '../../url.js';
import useAnalyticsData from '../../hooks/use-analytics-data.js';

interface ChangeLocationState {
  locationData: Array<IZipLookUpResponse>;
  zipFormData?: { [key: string]: Object };
  zipArray: Array<IZipLookUpResponse>;
  errorZip: boolean;
  isLoading: boolean;
  zipParam?: string;
}

interface ChangeLocationProps {}

const ChangeLocation: FC<ChangeLocationProps> = () => {
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const changeLocationContent = useTranslation('change-location');
  const planResultsContent = useTranslation('plan-results-content');
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const [changeLocationData, setChangeLocationData] = useState<ChangeLocationState>({
    locationData: [{} as IZipLookUpResponse],
    zipArray: [],
    errorZip: false,
    isLoading: false,
  });
  let changeLocationFormRef = useRef<HTMLFormElement>();

  const backToListView = () => {
    analyticsData.setCurrentPageTitle(planResultsContent.t('misc.pageTitle'));
    analyticsData.setCurrentView('plansView');

    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/';

    if (userData.location?.zip && userData.location?.fip) {
      urlObj.searchParams.set('zip', userData.location.zip);
      urlObj.searchParams.set('fip', userData.location.fip);
    }

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path);
  };

  const triggerSearch = (event: KeyboardEvent) => {
    const input = changeLocationFormRef.current!.querySelector('leaf-input')!;
    input.error = '';
    input.removeAttribute('invalid');
    if (event.key === 'Enter') {
      input.blur();
      (changeLocationFormRef.current!.querySelector('leaf-button[type=submit]') as HTMLElement)!.click();
    }
  };

  const filterZips = () => {
    setChangeLocationData((prevState) => ({ ...prevState, isLoading: true }));
    const zipValue = changeLocationData.zipFormData?.zipcode as string;
    memZipLookUp(zipValue)
      .then(async (response: IZipLookUpResponse[]) => {
        setChangeLocationData((prevState) => ({ ...prevState, locationData: response, errorZip: false }));

        if (response !== null) {
          response.forEach(
            (element: IZipLookUpResponse) => (element.zip = changeLocationData.zipFormData?.zipcode.toString() || ''),
          );
          setChangeLocationData((prevState) => ({ ...prevState, zipArray: response }));
          if (response.length === 1) {
            updateLocation(response[0]);
          } else if (response.length > 1) {
            const urlObj = new URL(window.location.href);
            urlObj.pathname = '/change-location/select-county';
            urlObj.searchParams.set('zip', zipValue);

            if (userData?.lastTabVisit && userData.lastTabVisit !== 'ma') {
              urlObj.hash = userData.lastTabVisit;
            }

            const path = urlObj.href.replace(urlObj.origin, '');
            navigate(path);
          } else {
            const input = changeLocationFormRef.current?.querySelector('leaf-input');
            input!.error = changeLocationContent.t('zipSearch.zipCodeNotFound');
          }

          setChangeLocationData((prevState) => ({ ...prevState, isLoading: false }));
        }
      })
      .catch(() => {
        setChangeLocationData((prevState) => ({ ...prevState, isLoading: false, errorZip: true }));
      });
  };

  const updateLocation = (zipObj: IZipLookUpResponse) => {
    userData.setLocation({
      zip: zipObj.zip,
      fip: zipObj.CountyFIPS,
      county: zipObj.CountyName,
      state: zipObj.State,
    });

    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/';
    urlObj.searchParams.set('zip', zipObj.zip);
    urlObj.searchParams.set('fip', zipObj.CountyFIPS);

    if (userData?.lastTabVisit && userData.lastTabVisit !== 'ma') {
      urlObj.hash = userData.lastTabVisit;
    }

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path);
  };

  const handleZipForm = (event: FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const zip = Object.fromEntries(formData).zipcode;
    setChangeLocationData((prevState) => ({ ...prevState, zipFormData: { zipcode: zip } }));
  };

  const zipQueryCheck = () => {
    const searchParams: ISearchParams = getParams(location.search);
    const sanitizedParams = JSON.parse(sanitize(JSON.stringify(searchParams)));
    const input = changeLocationFormRef.current!.querySelector('leaf-input');
    if (sanitizedParams['zip']) {
      changeLocationData.zipParam = sanitizedParams['zip'];
      if (input.querySelector('leaf-validation-message') || input.hasAttribute('valid')) {
        input.querySelector('input').setAttribute('valid', 'true');
        input.setAttribute('valid', '');
      } else setTimeout(() => zipQueryCheck(), 10);
    }
  };

  useEffect(() => {
    if (changeLocationData.zipFormData) {
      filterZips();
    }
  }, [changeLocationData.zipFormData]);

  useEffect(() => {
    zipQueryCheck();

    const updateInstance: IUserSession = {
      ...userData,
      year: userData.year || 2025,
    };
    userData.setStatus(updateInstance);
    const pageView = () => {
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: changeLocationContent.t('zipSearch.pageTitle'),
        currentView: 'zipSearchView',
        year: userData.year || 2025,
      };
      analyticsData.setStatus(updateAnalytics);
    };
    if (!document.title.includes(changeLocationContent.t('zipSearch.pageTitle'))) setTimeout(() => pageView(), 200);

    if (userData.location) {
      (changeLocationFormRef.current!.querySelector('h1') as HTMLElement)?.focus();
    }
  }, []);

  return (
    <>
      <div id="zip-county-search" className="zip-view">
        {changeLocationData.errorZip && (
          <leaf-alert variant="banner" type="error" class="errorZip" open>
            <p>{HTMLReactParser(changeLocationContent.t('zipSearch.errorZip'))}</p>
          </leaf-alert>
        )}
        <h1 className="leaf-u-typography-headline-default">{changeLocationContent.t('zipSearch.pageHeader')}</h1>
        <div className="row iw_row">
          <form
            action="/"
            onSubmit={handleZipForm}
            id="changeLocationForm"
            ref={changeLocationFormRef}
            className="columns iw_columns large-5"
          >
            <leaf-label for="zipcode">{changeLocationContent.t('zipSearch.zipCodeLabel')}</leaf-label>
            <leaf-input
              autocomplete="postal-code"
              id="zipcode"
              name="zipcode"
              required={true}
              pattern={regexToString(/^\d{5}$/)}
              mask="00000"
              type="tel"
              label={changeLocationContent.t('zipSearch.zipCodeLabel')}
              onKeyUp={triggerSearch}
              value={changeLocationData?.zipParam}
            ></leaf-input>
            <leaf-flex-container>
              <leaf-button type="submit" variant="primary">
                {changeLocationContent.t('zipSearch.shopPlanBtn')}
              </leaf-button>
              {((userData.location?.zip && userData.location?.fip) || !!routeLocation.state?.pathname) && (
                <leaf-button variant="tertiary" onClick={backToListView}>
                  {changeLocationContent.t('zipSearch.cancelBtn')}
                </leaf-button>
              )}
            </leaf-flex-container>
          </form>
        </div>
      </div>
      {changeLocationData.isLoading && (
        <leaf-spinner role="alert" aria-live="assertive" size="large" mode="indeterminate">
          <span>{changeLocationContent.t('zipSearch.loading')}</span>
        </leaf-spinner>
      )}
    </>
  );
};

export default ChangeLocation;
