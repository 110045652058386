import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Callout } from './callout.js';
import { Print } from './print.js';
import { Enroll } from './enroll.js';
import { IPlanDetailResponse } from '../../utils/api/interfaces.js';
import HTMLReactParser from 'html-react-parser';
import useUserData from '../../hooks/use-user-data.js';

interface HearingCoverageTabProps {
  localState: {
    isExpired?: boolean;
    selectedPharmacy: IPlanDetailResponse;
  };
}

const HearingCoverageTab: FC<HearingCoverageTabProps> = (props: HearingCoverageTabProps) => {
  const planDetailsContent = useTranslation('plan-details-content');
  const userData = useUserData();

  //Hearing Coverage is only for MAPD (Plan Type 2) & MA (Plan Type 4)
  const renderHearingBenefitCoverage = (IsOutOfNetwork = false) => {
    const returnMedicalBenefitHTML: Array<JSX.Element> = [];
    const data = props.localState.selectedPharmacy.planDetail.ExtendedPlanDetails.find((sections) =>
      sections.SectionDetails.find(
        (section) => section.Description?.indexOf('Hearing') > -1 && section?.IsOutOfNetwork === IsOutOfNetwork,
      ),
    );
    data?.SectionDetails?.forEach((HearingBenefit: { Description: string; Value: string }) => {
      returnMedicalBenefitHTML.push(
        <tr key={HearingBenefit.Description}>
          <th part="tbody-th">{HearingBenefit.Description}</th>
          <td>{HTMLReactParser(HearingBenefit.Value)}</td>
        </tr>,
      );
      return;
    });

    return (
      data && (
        <div className="outer width-table">
          <div className="inner">
            <chc-table table-header-color="orange">
              <table slot="table">
                <thead>
                  <tr>
                    <th>{planDetailsContent.t('hearingCoverageTab.planCategoryLabel')}</th>
                    <th>{planDetailsContent.t('hearingCoverageTab.planPaysLabel')}</th>
                  </tr>
                </thead>
                <tbody>{returnMedicalBenefitHTML}</tbody>
              </table>
            </chc-table>
          </div>
        </div>
      )
    );
  };

  const renderHearingCoverageTabSection = () => {
    let returnCoverageTabHTML;
    if (props.localState.selectedPharmacy.planDetail.PlanType !== 1) {
      returnCoverageTabHTML = (
        <leaf-tab-section slot="tab-section" label="hearingcoverage" has-hr="">
          <h2>{planDetailsContent.t('hearingCoverageTab.header')}</h2>
          <leaf-tab mobile-variant="scroll" tab-align="left">
            <leaf-tab-item
              slot="tab"
              scroll-target=""
              class="focus-visible"
              active="true"
              aria-selected="true"
              label="innetwork"
            >
              {planDetailsContent.t('misc.inNetwork')}
            </leaf-tab-item>
            {renderHearingBenefitCoverage(true) && (
              <leaf-tab-item slot="tab" label="outofnetwork">
                {planDetailsContent.t('misc.outOfNetwork')}
              </leaf-tab-item>
            )}
            <leaf-tab-section slot="tab-section" active="true" label="innetwork" has-hr="">
              {renderHearingBenefitCoverage()}
            </leaf-tab-section>
            {renderHearingBenefitCoverage(true) && (
              <leaf-tab-section slot="tab-section" label="outofnetwork" has-hr="">
                {renderHearingBenefitCoverage(true)}
              </leaf-tab-section>
            )}
          </leaf-tab>
          <leaf-flex-container>
            <Enroll userData={userData} headerOrFooter={'footer'} localState={props.localState} />
            <Print />
          </leaf-flex-container>
          <Callout />
        </leaf-tab-section>
      );
    }
    return returnCoverageTabHTML;
  };

  return <>{renderHearingCoverageTabSection()}</>;
};

export default HearingCoverageTab;
