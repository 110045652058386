import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { enroDRX } from '../../utils/apiHelper/igse-session.js';
import enOnlyContent from '../../../public/locales/en-only-content.json';
// Hooks
import useUserData from '../../hooks/use-user-data.js';
import useErrorStates from '../../hooks/use-error-states.js';
import './enroll-modal.scss';
import { customTagEvent } from '../../utils/analytics/utils.js';

interface EnrollModalProps {
  isExpired: boolean;
  selectedPlanType: number;
  selectedPlanName: string;
  selectedId: string;
  selectedPlanId: string;
  selectedContractId: string;
  selectedSegmentId: string;
  dataImpression: string;
  dataCustom: string;
}

interface IEnrollModalState {
  showSpinner: boolean;
}

const EnrollModal: FC<EnrollModalProps> = (props) => {
  const planContent = useTranslation('plan-details-content');
  const userData = useUserData();
  const hasErrorState = useErrorStates();
  let rootRef = useRef<HTMLDivElement>(null);
  const [enrollModalState, setEnrollModalState] = useState<IEnrollModalState>({
    showSpinner: false,
  });

  const dismissModal = () => {
    rootRef.current?.querySelector('leaf-modal')?.hide();
  };

  const EnrollDRX = (event: Event) => {
    setEnrollModalState({ showSpinner: true });
    const target = event.target as HTMLElement;
    const planType = props.dataImpression?.includes('Medicare Advantage') ? 'ma' : 'pdp';
    customTagEvent(target, `${planType}-plan-click`);
    enroDRX({
      session: userData,
      selectedPlan: props.selectedId,
    }).catch(() => {
      hasErrorState.setApiDownError(true);
      rootRef.current?.querySelector('leaf-modal')?.hide();
      window.scrollTo(0, 0);
    });
  };

  //Holding onto this in case the change is incorrect
  const enrollContent = () => {
    const thisContent: any = planContent.t('modal', { returnObjects: true });
    if (userData.year?.toString() === '2024') {
      if (props.isExpired) {
        if (props.selectedPlanType === 1 && thisContent.transferNewPDPPlan) {
          return <p>{HTMLReactParser(thisContent.transferNewPDPPlan)}</p>;
        } else if (!(props.selectedPlanType === 1) && thisContent.transferNewMAPlan) {
          return <p>{HTMLReactParser(thisContent.transferNewMAPlan)}</p>;
        }
      } else {
        if (props.selectedPlanType === 1 && thisContent.transferPDPPlan) {
          return <p>{HTMLReactParser(thisContent.transferPDPPlan)}</p>;
        } else if (thisContent.transferPlan) {
          return (
            <>
              <p>{HTMLReactParser(thisContent.transferPlan)}</p>
              {props.selectedPlanName?.indexOf('Secure') > -1 && (
                <p>{HTMLReactParser(thisContent.transferSecurePlan)}</p>
              )}
            </>
          );
        }
      }
    }
    return;
  };

  const enrollButtons = () => (
    <div slot="cta">
      <leaf-flex-container>
        <div className="enroll-modal">
          <div className="enroll-yes-button">
            <leaf-button
              slot="footer"
              data-custom={props.dataCustom}
              data-en-content={enOnlyContent.plansView.enrollExit.replace('${planYear}', userData.year)}
              size="default"
              type="button"
              variant="primary"
              onClick={EnrollDRX}
            >
              {planContent.t('modal.yesEnroll').replace('${year}', userData.year)}
            </leaf-button>
          </div>
          <leaf-button-link
            class="cancel-button"
            onClick={dismissModal}
            data-en-content={enOnlyContent.plansView.enrollModalCancel}
            data-custom="prop13:ac24775^prop20:Plan Enrollment Confirmation^eVar119:CSTD-3126^eVar120:link-shop-plans-enrollment-confirmation-cancel-modal-close"
          >
            {planContent.t('modal.noCancel')}
          </leaf-button-link>
          {enrollModalState.showSpinner && (
            <div className="enroll-spinner">
              <leaf-spinner role="alert" aria-live="assertive" size="large" mode="indeterminate">
                <span>{planContent.t('modal.loading')}</span>
              </leaf-spinner>
            </div>
          )}
        </div>
      </leaf-flex-container>
    </div>
  );

  return (
    <div ref={rootRef} className="enroll-modal-wrapper">
      <leaf-modal id="EnrollThisPlan" size="md" data-impression={props.dataImpression} ref={rootRef}>
        <h4 slot="heading">{planContent.t('modal.heading')}</h4>
        {enrollContent()}
        <p className="confirm-modal">{planContent.t('modal.confirmMessage')}</p>
        {enrollButtons()}
      </leaf-modal>
    </div>
  );
};

export default EnrollModal;
