import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './SelectCounty.scss';
import { sanitize } from '../../utils/sanitize.js';
import { memZipLookUp } from '../../utils/memoize/memZipCountySearch.js';
import { IZipLookUpResponse } from '../../utils/api/zip-county-search.js';
import { ISearchParams, getParams, updateQueryString } from '../../url.js';

// Hooks
import useUserData from '../../hooks/use-user-data.js';
import { IUserSession } from '../../context/user-context.js';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { IAnalytics } from '../../context/analytics-context.js';

interface SelectCountyState {
  locationData: Array<IZipLookUpResponse>;
  zipCode?: string;
  zipArray: Array<IZipLookUpResponse>;
}

interface SelectCountyProps {}

const SelectCounty: FC<SelectCountyProps> = () => {
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const changeLocationContent = useTranslation('change-location');
  const planResultsContent = useTranslation('plan-results-content');
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const [selectCountyData, setSelectCountyData] = useState<SelectCountyState>({
    locationData: [{} as IZipLookUpResponse],
    zipArray: [],
  });

  const backToListView = () => {
    analyticsData.setCurrentPageTitle(planResultsContent.t('misc.pageTitle'));
    analyticsData.setCurrentView('plansView');

    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/';

    if (userData?.lastTabVisit && userData.lastTabVisit !== 'ma') {
      urlObj.hash = userData.lastTabVisit;
    }

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path);
  };

  const filterZips = () => {
    memZipLookUp(selectCountyData.zipCode)
      .then(async (response: IZipLookUpResponse[]) => {
        setSelectCountyData((prevState) => ({ ...prevState, locationData: response }));

        if (response !== null) {
          response.forEach((element: IZipLookUpResponse) => (element.zip = selectCountyData.zipCode?.toString() || ''));
          setSelectCountyData((prevState) => ({ ...prevState, zipArray: response }));
        }
      })
      .catch(() => {});
  };

  const updateLocation = (zipObj: IZipLookUpResponse) => {
    userData.setLocation({
      zip: zipObj.zip,
      fip: zipObj.CountyFIPS,
      county: zipObj.CountyName,
      state: zipObj.State,
    });
    const currentParams = getParams(window.location.search);
    const sanitizedParams = JSON.parse(sanitize(JSON.stringify(currentParams)));
    sanitizedParams.zip = zipObj.zip;
    sanitizedParams.fip = zipObj.CountyFIPS;
    const qs = updateQueryString(sanitizedParams, window.location);
    window.history.replaceState('', '', `?${qs}`);
    backToListView();
  };

  const back = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/change-location';

    if (userData?.lastTabVisit && userData.lastTabVisit !== 'ma') {
      urlObj.hash = userData.lastTabVisit;
    }

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, { state: routeLocation });
  };

  const handleFipForm = (event: FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const fip = Object.fromEntries(formData).county;
    const zipArray = selectCountyData.locationData.filter((code: IZipLookUpResponse) => {
      return code.zip == selectCountyData.zipCode && code.CountyFIPS == fip;
    });
    if (zipArray.length > 0) {
      updateLocation(zipArray[0]);
    }
  };

  useEffect(() => {
    if (selectCountyData.zipCode) {
      filterZips();
    }
  }, [selectCountyData.zipCode]);

  useEffect(() => {
    const searchParams: ISearchParams = getParams(location.search);
    const sanitizedParams = JSON.parse(sanitize(JSON.stringify(searchParams)));

    if (sanitizedParams['zip']) {
      setSelectCountyData((prevState) => ({ ...prevState, zipCode: sanitizedParams['zip'] }));

      const updateInstance: IUserSession = {
        ...userData,
      };
      userData.setStatus(updateInstance);
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: changeLocationContent.t('countyView.pageTitle'),
        currentView: 'countyLookupView',
        year: userData.year || 2025,
      };
      analyticsData.setStatus(updateAnalytics);
    } else {
      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/change-location';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, { replace: true });
    }
  }, []);

  if (selectCountyData.zipArray.length === 0) {
    return (
      <leaf-spinner role="alert" aria-live="assertive" variant="large" mode="indeterminate">
        <span>{changeLocationContent.t('zipSearch.loading')}</span>
      </leaf-spinner>
    );
  }

  return (
    <div className="county-view" id="zip-county-search">
      <leaf-button variant="tertiary" classes="leaf-u-link--no-underline" class="back-btn" onClick={back}>
        <leaf-icon name="keyboard-arrow-left"></leaf-icon>&nbsp;
        {changeLocationContent.t('zipSearch.backBtn')}
      </leaf-button>
      <h2 className="leaf-u-typography-headline-default">{changeLocationContent.t('countyView.modalTitle')}</h2>
      <p>{changeLocationContent.t('countyView.modalBodyText')}</p>
      <div className="row iw_row">
        <form action="/" onSubmit={handleFipForm} className="columns iw_columns large-5">
          <leaf-label for="county-input">
            {changeLocationContent.t('countyView.modalCountyLabel')} {selectCountyData.zipCode}
          </leaf-label>
          <leaf-select name="county" id="county-input" required>
            <leaf-select-option value="" selected disabled id="fip">
              {changeLocationContent.t('countyView.selectCounty')}
            </leaf-select-option>
            {selectCountyData.zipArray.map((zip: IZipLookUpResponse, index) => (
              <leaf-select-option key={index} value={zip.CountyFIPS}>
                {zip.CountyName}
              </leaf-select-option>
            ))}
          </leaf-select>
          <leaf-flex-container>
            <leaf-button type="submit" variant="primary">
              {changeLocationContent.t('countyView.modalSubmit')}
            </leaf-button>
          </leaf-flex-container>
        </form>
      </div>
    </div>
  );
};

export default SelectCounty;
