import React, { FC } from 'react';
import { Result, PlanTool } from './interfaces.js';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

interface ResultsSectionProps {}

const ResultsSection: FC<ResultsSectionProps> = () => {
  const shoppingWizardData = useTranslation('shopping-wizard');
  const resultsData: Array<Result> = shoppingWizardData.t('results', { returnObjects: true });
  const planData: PlanTool = shoppingWizardData.t('planTool', { returnObjects: true });

  const goToTab = (result: Result) => {
    (document.querySelector('#shopping-wizard-modal') as any)?.hide();
    (
      document
        ?.querySelector(`leaf-tab-item[label="${result.result === 'B' ? 'ma' : 'supplemental'}"]`)
        ?.shadowRoot?.querySelector('a') as HTMLElement
    )?.click();
    (document.querySelector('#wizard-start-over') as HTMLElement)?.click();
  };

  return (
    <>
      {resultsData.map((result: Result, ind) => (
        <div className="results" data-result={result.result} hidden key={`res1-${ind + 1}`}>
          <div>{HTMLReactParser(result.paragraph)}</div>
          <ul id="results-list" key={`results-list-${ind + 1}`}>
            {result.listContents.map((listItem, index) => (
              <li key={`res-${ind + 1}-list-${index + 1}`}>{HTMLReactParser(listItem.content)}</li>
            ))}
          </ul>
          <p>
            <leaf-button
              key={`result-btn-${ind + 1}`}
              class="result-btn"
              variant="primary"
              onClick={() => goToTab(result)}
            >
              {HTMLReactParser(planData.leadin.replace('${answer}', result.answer))}
            </leaf-button>
          </p>
        </div>
      ))}
      {resultsData.map((result, ind) => (
        <div className="results" data-result={result.result} hidden key={`res2-${ind + 1}`}>
          {result.phoneLead && HTMLReactParser(result.phoneLead)}
          <leaf-list key={`results-lead-${ind + 1}`}>
            <h4 className="leaf-u-color-content-brand-strong" slot="heading">
              {result.header}
            </h4>
            {result.links.map((linkItem, index) => (
              <li key={'resultsother' + index}>{HTMLReactParser(linkItem.link)}</li>
            ))}
          </leaf-list>
        </div>
      ))}
    </>
  );
};

export default ResultsSection;
