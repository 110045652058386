import React, { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from '../../utils/types.js';
// Hooks
import useUserData from '../../hooks/use-user-data.js';
import useErrorStates from '../../hooks/use-error-states.js';
import useDrugSelector from '../../hooks/use-drug-selector.js';
import { IUserSession } from '../../context/user-context.js';
import TypeaheadSearch from './typeahead-search.js';
import './search.scss';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { IAnalytics } from '../../context/analytics-context.js';

interface SearchProps {
  isDrugListView?: boolean;
  handleDrugAlert: TFunction;
}

const Search: FC<SearchProps> = (props: SearchProps) => {
  const drugContent = useTranslation('drug-search');
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const hasErrorState = useErrorStates();
  const drugSelectorState = useDrugSelector();

  const goBackToPreviousView = () => {
    navigate(-1);
  };

  const goToPharmacy = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/pharmacy/';

    const path = urlObj.href.replace(urlObj.origin, '');

    if (routeLocation.state?.pageNumber) {
      navigate(path, { state: { pageNumber: routeLocation.state.pageNumber + 1 } });
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    const pageTitle = drugContent.t('drugSearch.pageTitleAdd');
    if (drugSelectorState.dosageList.length === 0) {
      if (routeLocation.state?.pathname) {
        const updateInstance: IUserSession = {
          ...userData,
        };
        userData.setStatus(updateInstance);
        const previousViewPath = { ...userData.previousViewPath, prescriptionView: routeLocation.state?.pathname };
        userData.setPreviousViewPath(previousViewPath);
        hasErrorState.setDrugSearchError(false);
      } else {
        analyticsData.setCurrentPageTitle(pageTitle);
      }
    }
    const setPageView = () => {
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: pageTitle,
        currentView: 'drugSearchViewAdd',
        year: userData.year || 2025,
      };
      analyticsData.setStatus(updateAnalytics);
    };
    if (!document.title.includes(pageTitle)) setTimeout(() => setPageView(), 200);
  }, []);

  useEffect(() => {
    if (drugSelectorState.dosageList.length > 0) {
      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/prescriptions/view-list';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, { ...routeLocation, replace: true });
    }
  }, [drugSelectorState.dosageList]);

  return (
    <div className="prescription-search" data-testid="Search">
      <div>
        <div className="row iw_row">
          <div className="columns iw_columns large-7 drug-search-form">
            <div>
              <h1>{drugContent.t('typeaheadSearch.prescriptionsLabel')}</h1>
              <p className="prescription-label">
                {!props.isDrugListView
                  ? drugContent.t('typeaheadSearch.addPrescriptionLabel')
                  : drugContent.t('typeaheadSearch.addAnother')}
              </p>

              <TypeaheadSearch handleDrugAlert={props.handleDrugAlert} />

              <div className="btn-group">
                <leaf-flex-container>
                  <leaf-button variant="tertiary" class="btn-cancel" onClick={goBackToPreviousView}>
                    {drugContent.t('misc.cancelRemoveLabel')}
                  </leaf-button>
                  {!props.isDrugListView ? (
                    <leaf-button variant="tertiary" class="btn-cancel" onClick={goToPharmacy}>
                      {drugContent.t('misc.skipButton')}
                    </leaf-button>
                  ) : (
                    <leaf-button class="pharmacy-btn" onClick={goToPharmacy}>
                      {drugContent.t('typeaheadSearch.nextButtonLabel')}
                    </leaf-button>
                  )}
                </leaf-flex-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
