import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ExtraHelp.scss';
import useUserData from '../../hooks/use-user-data.js';
import { editSession } from '../../utils/api/igse-session.js';
import { IUserSession } from '../../context/user-context.js';
import { INavigateOptions } from '../../utils/api/interfaces.js';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { IAnalytics } from '../../context/analytics-context.js';

interface OptionDetail {
  name: string;
  lisLevel?: number;
  default?: string;
  sublevel?: number;
  subperc?: number;
  optionLevel?: number;
}

interface HelpOption extends OptionDetail {
  subHeader?: string;
  subOptionName?: string;
  subOptionRequiredMsg?: string;
  subList?: Array<OptionDetail>;
}

interface ExtraHelpProps {
  misc?: {
    header: string;
    introTopContent: string;
    introBottomContent: string;
    cancelButtonText: string;
    skipButtonText: string;
    nextButtonText: string;
  };
  helpOptions?: {
    header: string;
    optionName: string;
    optionRequiredMsg: string;
    optionsList: Array<HelpOption>;
  };
}

interface IExtraHelpState {
  selectedHelpOption: string;
}

const ExtraHelp: FC<ExtraHelpProps> = () => {
  const extraHelpContent = useTranslation('extra-help-content');
  const navigate = useNavigate();
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const routeLocation = useLocation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [extraHelpData, setExtraHelpData] = useState<IExtraHelpState>({
    selectedHelpOption: '',
  });

  const checkRadioGroupOption = (radioGroupElement: HTMLInputElement, radioGroupValue: string) => {
    radioGroupElement.querySelectorAll('leaf-radio').forEach((option: HTMLInputElement) => {
      option.checked = option.value === radioGroupValue;
    });
  };

  const updatedSelectedValue = async () => {
    const subsidyRadioGroup = rootRef.current?.querySelector('leaf-radiogroup');
    await subsidyRadioGroup?.updateComplete;
    if (subsidyRadioGroup) {
      if (userData.subsidyPercent) {
        const subsidyPercentRadioGroup = subsidyRadioGroup!.shadowRoot!.querySelector('leaf-radiogroup');
        subsidyPercentRadioGroup!.value = userData.subsidyPercent;
        checkRadioGroupOption(subsidyPercentRadioGroup, userData.subsidyPercent);
      }
      let selectedHelpOption = '';
      if (userData.subsidyLevel) {
        selectedHelpOption = userData.extraHelpOptionLevel ?? '';
      } else {
        const helpOptions = extraHelpContent.t('helpOptions', { returnObjects: true });
        const defaultHelpOption = Array.from(helpOptions.optionsList).find(
          (option) => (option as OptionDetail).default === 'true',
        );
        selectedHelpOption = String((defaultHelpOption as OptionDetail).optionLevel) ?? '';
      }
      checkRadioGroupOption(subsidyRadioGroup, selectedHelpOption);
      setExtraHelpData((prevState) => ({ ...prevState, selectedHelpOption: selectedHelpOption }));
      subsidyRadioGroup!.value = selectedHelpOption;
    }
  };

  useEffect(() => {
    const updateInstance: IUserSession = {
      ...userData,
    };
    userData.setStatus(updateInstance);
    const setPageView = () => {
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: extraHelpContent.t('misc.pageTitle'),
        currentView: 'extraHelpView',
        year: userData.year || 2025,
      };
      analyticsData.setStatus(updateAnalytics);
    };
    if (!document.title.includes(extraHelpContent.t('misc.pageTitle'))) setTimeout(() => setPageView(), 200);
    if (routeLocation.state?.pathname) {
      const previousViewPath = { ...userData.previousViewPath, extraHelpView: routeLocation.state?.pathname };
      userData.setPreviousViewPath(previousViewPath);
    }
    setTimeout(() => updatedSelectedValue(), 500);
  }, []);

  const goBackToPreviousView = () => {
    navigate(-1);
  };

  const goToPrescription = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/prescriptions/';

    const pathName = window.location.href.replace(window.location.origin, '');
    const options: INavigateOptions = { state: { pathname: pathName, ...routeLocation.state } };

    if (routeLocation.state?.pageNumber) {
      options.state.pageNumber = routeLocation.state.pageNumber + 1;
    }

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, options);
  };

  const renderSubOptions = (option: OptionDetail) => {
    const subOptionsHTML: Array<JSX.Element> = [];

    if (option.subperc) {
      subOptionsHTML.push(
        <leaf-radio key={option.subperc} value={option.subperc} data-lislevel={option.lisLevel}>
          {option.name}
        </leaf-radio>,
      );
    }

    if (!option.subperc) {
      subOptionsHTML.push(
        <leaf-radio key={option.name} value={option.optionLevel} data-lislevel={option.lisLevel}>
          {option.name}
        </leaf-radio>,
      );
    }
    return subOptionsHTML;
  };

  const renderSubListOptions = (subOptions: OptionDetail[]) => {
    const subListHTML: Array<JSX.Element> = [];
    subOptions?.forEach((subOption: OptionDetail) => {
      const helpOptionHtml: Array<JSX.Element> = renderSubOptions(subOption);
      subListHTML.push(...helpOptionHtml);
    });
    return subListHTML;
  };

  const renderAllHelpOptions = () => {
    const returnHTML: Array<JSX.Element> = [];
    const helpOptions = extraHelpContent.t('helpOptions', { returnObjects: true });
    helpOptions.optionsList.forEach((list: HelpOption) => {
      if (userData.year?.toString() !== '2023') {
        const helpOptionHtml: Array<JSX.Element> = renderSubOptions(list);
        returnHTML.push(...helpOptionHtml);
      } else if (list.subList && list.subList.length > 0 && userData.year?.toString() === '2023') {
        returnHTML.push(
          <leaf-radiogroup key={list.sublevel}>
            {renderSubOptions(list)}
            <leaf-radiogroup key={list.sublevel} name={list.subOptionName} label={list.subHeader} required>
              {renderSubListOptions(list.subList)}
            </leaf-radiogroup>
          </leaf-radiogroup>,
        );
      }
    });
    return returnHTML;
  };

  const subsidyAPI = async (subsidyLevel: string, subsidyPercent: string) => {
    try {
      await editSession({
        session: userData.session,
        sub: { help_option: subsidyLevel, partialhelp_option: subsidyPercent },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const goNext = (event: FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    if (!form?.querySelector('[invalid]')) {
      const formData = new FormData(form);
      const help_option = String(formData.get('help_option'));
      const partialhelp_option =
        (formData.get('partialhelp_option') && String(formData.get('partialhelp_option'))) ?? '';

      const helpOptions = extraHelpContent.t('helpOptions', { returnObjects: true });
      const subsidyLevel = helpOptions.optionsList.find((list: OptionDetail) => list.optionLevel === +help_option);
      userData.setExtraHelpOptionLevel(help_option);
      userData.setSubsidyLevel(
        userData.year?.toString() === '2023' || help_option !== '4' ? subsidyLevel.sublevel.toString() : '1',
      );
      userData.setSubsidyPercent(partialhelp_option);
      subsidyAPI(subsidyLevel.sublevel, partialhelp_option);
      goToPrescription();
    }
  };

  return (
    <div className="extra-help" data-testid="ExtraHelp" ref={rootRef}>
      {
        <>
          <div className="back-btn-wrapper">
            <leaf-button variant="tertiary" onClick={() => goBackToPreviousView()}>
              <leaf-icon
                slot="icon-before"
                label={extraHelpContent.t('misc.backButtonText')}
                name="keyboard-arrow-left"
              ></leaf-icon>
              {extraHelpContent.t('misc.backButtonText')}
            </leaf-button>
          </div>
          <h1>{extraHelpContent.t('misc.header')}</h1>
          <p>{extraHelpContent.t('misc.introTopContent')}</p>
          <p>{extraHelpContent.t('misc.introBottomContent')}</p>
          <form name="extra-help-options" onSubmit={goNext}>
            <leaf-label for={extraHelpContent.t('helpOptions.optionName')}>
              {extraHelpContent.t('helpOptions.header')}
            </leaf-label>
            <leaf-radiogroup
              id={extraHelpContent.t('helpOptions.optionName')}
              name={extraHelpContent.t('helpOptions.optionName')}
              required
              value={extraHelpData.selectedHelpOption}
            >
              {renderAllHelpOptions()}
            </leaf-radiogroup>
            <leaf-flex-container>
              <leaf-button type="submit" variant="primary">
                {extraHelpContent.t('misc.nextButtonText')}
              </leaf-button>
              <leaf-button variant="tertiary" onClick={() => goToPrescription()}>
                {extraHelpContent.t('misc.skipButtonText')}
              </leaf-button>
            </leaf-flex-container>
          </form>
        </>
      }
    </div>
  );
};

export default ExtraHelp;
