import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import './discount-modal.scss';

import useUserData from '../../hooks/use-user-data.js';
import { IRateDetail } from '../../utils/api/interfaces.js';

interface DiscountModalProps {
  ratesObj: Array<IRateDetail>;
  monthlyMultiplier: number;
  lowestRateObj: IRateDetail;
}

interface IDiscountModalState {
  count: number;
  discountText: string;
  discountHtml: JSX.Element[];
}

const DiscountModal: FC<DiscountModalProps> = (props: DiscountModalProps) => {
  const userData = useUserData();
  const suppContent = useTranslation('plan-results-supp-content');
  const [discountModalState, setDiscountModalState] = useState<IDiscountModalState>({
    count: 0,
    discountText: '',
    discountHtml: [],
  });

  useEffect(() => {
    if (props.ratesObj) {
      let count = 0;
      let SEOnlyDiscount, LwsOnlyDiscount, HhdOnlyDiscount;
      const annualNoDiscount = props.ratesObj.find(
        (el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=NO',
      )?.rateAmount;
      const discountHtml: JSX.Element[] = [];
      if (
        userData.location?.state &&
        !['KS', 'ND', 'OH'].includes(userData.location?.state) &&
        props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE ONLY')
      ) {
        SEOnlyDiscount = props.ratesObj.find(
          (el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE ONLY',
        )?.rateAmount;
        count += 1;
      }

      if (
        userData.location?.state &&
        !['HI', 'IA', 'ME', 'OK', 'OH'].includes(userData.location?.state) &&
        props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS ONLY')
      ) {
        LwsOnlyDiscount = props.ratesObj.find(
          (el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS ONLY',
        )?.rateAmount;
        count += 1;
      }

      if (userData.location?.state && !['HI', 'OK'].includes(userData.location?.state)) {
        if (props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=YES')) {
          HhdOnlyDiscount = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=YES')?.rateAmount;
          count += 1;
        } else if (
          props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE AND HHD') &&
          props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS ONLY')
        ) {
          const se = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE ONLY');
          const lws = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS ONLY');
          const seHhd = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE AND HHD');
          if (annualNoDiscount && se && lws && seHhd) {
            const hhdDiscount =
              annualNoDiscount -
              seHhd.rateAmount -
              (annualNoDiscount - se.rateAmount) -
              (annualNoDiscount - lws.rateAmount);
            HhdOnlyDiscount = annualNoDiscount - hhdDiscount;
          }
          count += 1;
        } else if (
          props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE AND HHD') &&
          !props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS ONLY') &&
          !props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=YES')
        ) {
          const se = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE ONLY');
          const seHhd = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE AND HHD');
          if (annualNoDiscount && se && seHhd) {
            const hhdDiscount = annualNoDiscount - seHhd.rateAmount - (annualNoDiscount - se.rateAmount);
            HhdOnlyDiscount = annualNoDiscount - hhdDiscount;
          }
          count += 1;
        } else if (props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS AND HHD')) {
          const lws = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS ONLY');
          const lwsHhd = props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS AND HHD');
          if (annualNoDiscount && lws && lwsHhd) {
            const hhdDiscount = annualNoDiscount - lwsHhd.rateAmount - (annualNoDiscount - lws.rateAmount);
            HhdOnlyDiscount = annualNoDiscount - hhdDiscount;
          }
          count += 1;
        }
      }

      if (userData.location?.state && ['CT', 'FL', 'NJ', 'OH', 'OK'].includes(userData.location?.state)) {
        if (props.ratesObj.find((el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS AND HHD')) {
          HhdOnlyDiscount = props.ratesObj.find(
            (el: any) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS AND HHD',
          )?.rateAmount;
          count += 1;
        }
      }

      const discountText =
        count > 1
          ? suppContent.t('discountModal.discounts').toLowerCase()
          : suppContent.t('discountModal.discount').toLowerCase();

      if (annualNoDiscount && SEOnlyDiscount) {
        const SEPercent = (((annualNoDiscount - SEOnlyDiscount) / annualNoDiscount) * 100).toFixed();
        const SEValue = ((annualNoDiscount - SEOnlyDiscount) * props.monthlyMultiplier).toFixed(2);
        discountHtml.push(
          <tr>
            <td className="left">
              {HTMLReactParser(suppContent.t('discountModal.selfEnroll').replace('${percent}', SEPercent))}
            </td>
            <td className="right">-${SEValue}</td>
          </tr>,
        );
      }

      if (annualNoDiscount && LwsOnlyDiscount) {
        const LwsPercent = (((annualNoDiscount - LwsOnlyDiscount) / annualNoDiscount) * 100).toFixed();
        const LwsValue = ((annualNoDiscount - LwsOnlyDiscount) * props.monthlyMultiplier).toFixed(2);
        if (userData.location?.state && ['PA'].includes(userData.location?.state)) {
          discountHtml.push(
            <tr>
              <td className="left">
                {HTMLReactParser(suppContent.t('discountModal.cic').replace('${percent}', LwsPercent))}
              </td>
              <td className="right">-${LwsValue}</td>
            </tr>,
          );
        } else if (
          userData.location?.state &&
          ['AR', 'CA', 'CO', 'IN', 'KS', 'LA', 'MO', 'NM', 'NV', 'TN', 'TX'].includes(userData.location?.state)
        ) {
          discountHtml.push(
            <tr>
              <td className="left">
                {HTMLReactParser(suppContent.t('discountModal.lwsWithFiftyOld').replace('${percent}', LwsPercent))}
              </td>
              <td className="right">-${LwsValue}</td>
            </tr>,
          );
        } else if (userData.location?.state && ['MT'].includes(userData.location?.state)) {
          discountHtml.push(
            <tr>
              <td className="left">
                {HTMLReactParser(suppContent.t('discountModal.lwsMT').replace('${percent}', LwsPercent))}
              </td>
              <td className="right">-${LwsValue}</td>
            </tr>,
          );
        } else {
          discountHtml.push(
            <tr>
              <td className="left">
                {HTMLReactParser(suppContent.t('discountModal.lws').replace('${percent}', LwsPercent))}
              </td>
              <td className="right">-${LwsValue}</td>
            </tr>,
          );
        }
      }

      if (annualNoDiscount && HhdOnlyDiscount) {
        const HhdPercent = (((annualNoDiscount - HhdOnlyDiscount) / annualNoDiscount) * 100).toFixed();
        const HhdValue = ((annualNoDiscount - HhdOnlyDiscount) * props.monthlyMultiplier).toFixed(2);
        if (userData.location?.state && ['CA', 'LA', 'NM', 'NV', 'KS'].includes(userData.location?.state)) {
          discountHtml.push(
            <tr>
              <td className="left">
                {HTMLReactParser(suppContent.t('discountModal.hhdCALANMNVKS').replace('${percent}', HhdPercent))}
              </td>
              <td className="right">-${HhdValue}</td>
            </tr>,
          );
        } else {
          discountHtml.push(
            <tr>
              <td className="left">
                {HTMLReactParser(suppContent.t('discountModal.hhd').replace('${percent}', HhdPercent))}
              </td>
              <td className="right">-${HhdValue}</td>
            </tr>,
          );
        }
      }

      setDiscountModalState((prevState) => ({ ...prevState, count, discountText, discountHtml }));
    }
  }, [props.ratesObj]);

  return (
    <div className="discount-modal" data-testid="DiscountModal">
      <table>
        <thead>
          <tr>
            <th scope="row" className="left">
              {HTMLReactParser(
                suppContent.t('discountModal.qualifyText').replace('${discount}', discountModalState.discountText),
              )}
            </th>
            <th scope="row" className="right">
              {discountModalState.count > 1
                ? suppContent.t('discountModal.discounts')
                : suppContent.t('discountModal.discount')}
            </th>
          </tr>
        </thead>
        <tbody>
          {discountModalState.discountHtml.map((discountElement, index) => (
            <Fragment key={index}>{discountElement}</Fragment>
          ))}
        </tbody>
      </table>
      <hr />
      <p>
        <small>{suppContent.t('discountModal.footnote')}</small>
      </p>
    </div>
  );
};

export default DiscountModal;
